.about-intro-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 1110px;
  margin: 56px auto;
  gap: 60px;
}

.about-intro-text {
  color: #000;
  font-family: Lato;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 30px; /* 150% */
}

.about-intro-img-section {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
}

.about-logo {
  width: 330px;
  height: 107px;
  margin-top: 0;
  margin-bottom: 24px;
}

.about-logo img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.about-founder {
  width: 332px;
  height: 372px;
}

.about-founder img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.about-founder-name {
  color: var(--Gray-900---Main-Text, #181e27);
  font-family: Lato;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 175%; /* 31.5px */
  text-align: left;
}

.about-founder-designation {
  color: var(--Gray-600---Secondary-Text, #3c465f);
  font-family: Lato;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 24px */
  text-align: left;
}

@media screen and (max-width: 768px) {
  .about-intro-container {
    width: 100vw;
    flex-direction: column-reverse;
    margin-left: 32px;
    gap: 30px;
  }

  .about-logo {
    display: none;
  }

  .about-founder {
    width: 100vw;
    height: 100%;
  }
}
