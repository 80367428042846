.faq-card {
  width: 350px;
  height: 171px;
  position: relative;
  background: var(--Color-White, #fff);
  box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.1);
}

.faq-card.selected {
  background: #dc913f;
  box-shadow: none;
  color: white;
}

.faq-card-question {
  width: 80%;
  text-align: center;
  font-family: Lora;
  font-size: 26px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  position: relative;
  padding: 42px 36px;
}

.faq-down-btn {
  position: absolute;
  bottom: -30px;
  left: 151px;
  width: 48px;
  height: 64px;
  transform: rotate(90deg);
  background: #dc913f;
  cursor: pointer;
}

.faq-down-btn.selected {
  background: #fff;
  box-shadow: 0px 4px 16px 0px #dc913f5a;
}

.faq-down-btn-svg {
  transform: rotate(-90deg);
  position: relative;
  padding: 0 26px 5px 0;
}

.faq-down-btn-svg-path {
  fill: #fff;
}

.faq-down-btn-svg-path.selected {
  fill: #dc913f;
}

@media screen and (max-width: 768px) {
  .faq-card {
    width: 80%;
    height: 30%;
  }

  .faq-card-question {
    width: 80%;
    font-size: 18px;
    padding: 15% 10%;
  }

  .faq-down-btn {
    width: 15%;
    height: 45%;
    left: 42.5%;
  }

  .faq-down-btn-svg {
    padding: 0 18px 7px 0;
  }

}
