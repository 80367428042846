.why-beyond {
  width: 1102px;
  height: 430px;
  display: flex;
  flex-direction: row;
  margin: 100px auto;
}

.why-beyond-features {
  display: flex;
  flex-direction: row;
}

.features-line {
  display: flex;
  flex-direction: column;
  gap: 64px;
  margin: auto 0;
}

.features-line {
  display: flex;
  flex-direction: column;
  gap: 64px;
  margin: auto 0;
}

.feature-item {
  display: flex;
  flex-direction: row;
  height: 75px;
  gap: 24px;
  width: 275px;
}

.feature-icon {
  width: 32px;
  height: 32px;
  margin-top: 4px;
  margin-bottom: auto;
}

.feature-desc {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 8px;
}

.feature-highlight {
  color: #10375c;
  font-family: Lora;
  font-size: 32px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.feature-description {
  color: var(--Color-Gray-1, #9a9a9a);
  font-family: Lato;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 21px;
}

.why-beyond-quote {
  display: flex;
  flex-direction: column;
  width: 540px;
  height: 430px;
  background: #10375c;
}

.title-line {
  display: flex;
  flex-direction: row;
  gap: 15px;
  margin: 54px 0 0 53px;
}

.title {
  color: var(--Color-White, #fff);
  font-family: Lato;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 30px; /* 150% */
}

.why-stroke {
  width: 73px;
  height: 2px;
  background: #fff;
  margin: auto 0;
}

.why-slogan {
  color: var(--Color-White, #fff);
  font-family: Lora;
  font-size: 40px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin: 8px 54px 0 53px;
}

.why-description {
  color: var(--Color-White, #fff);
  font-family: Lato;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 30px; /* 150% */
  margin: 24px 54px 0 53px;
}

@media screen and (max-width: 768px) {
  .why-beyond {
    width: 100vw;
    height: 650px;
    display: flex;
    flex-direction: column-reverse;
    margin: 0;
    margin-top: 140px;
    gap: 20px;
  }

  .why-beyond-features {
    padding: 0 40px;
    width: 90vw;
    gap: 20px;
    margin-top: 30px;
  }

  .feature-highlight {
    font-size: 24px;
  }

  .feature-item {
    width: 50vw;
  }

  .why-beyond-quote {
    padding: 0;
    width: 120vw;
    height: fit-content;
  }

  .title-line {
    margin: 35px 0 0 10vw;
    gap: 7px;
  }

  .title {
    font-size: 16px;
  }

  .why-stroke {
    width: 50px;
  }

  .why-slogan {
    font-size: 30px;
    margin: 8px 10vw 0 10vw;
  }

  .why-description {
    font-size: 16px;
    margin: 16px 10vw 0 10vw;
    padding-bottom: 25px;
  }
}
