.vision {
  width: 1110px;
  display: flex;
  align-items: center;
  gap: 54px;
  margin: 64px auto;
}

.vision-text {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 11px;
  margin: auto 0;
}

.vision-title {
  color: #000;
  font-family: "Source Serif Pro";
  font-size: 36px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.vision-stroke {
  width: 309px;
  height: 3px;
  background: #dc913f;
}

.vision-desc {
  width: 528px;
  color: var(--Gray-1, #333);
  font-family: Lato;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.vision-img {
  width: 528px;
  height: 320px;
}

.vision-img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

@media screen and (max-width: 768px) {
  .vision {
    width: 100vw;
    flex-direction: column;
    margin-left: 32px;
  }

  .vision-img {
    width: 100vw;
    height: auto;
  }

  .vision-text {
    width: 100vw;
    gap: 20px;
  }

  .vision-stroke {
    width: 50%;
  }

  .vision-desc {
    width: 100vw;
  }
}
