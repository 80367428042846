.facilities {
  width: 1147px;
  height: 649px;
  margin: 0 auto;
  margin-top: 50px;
}

.facilities-title {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.facilities-title-line {
  display: flex;
  flex-direction: row;
  gap: 15px;
}

.facilities-title-text {
  color: var(--Color-Black, #222831);
  font-family: Lato;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 30px; /* 150% */
}

.facilities-title-stroke {
  width: 73px;
  height: 2px;
  background: var(--Color-Black, #222831);
  margin: auto 0;
}

.title-desc {
  color: #10375c;
  font-family: Lora;
  font-size: 40px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  width: 570px;
}

.facilities-container {
  margin-top: 56px;
  display: flex;
  flex-direction: row;
  gap: 48px;
}

.facilities-selector {
  display: flex;
  flex-direction: row;
}

.indicator-line {
  height: 28px;
  width: 235px;
  margin: 100px 0 0 -110px;
  display: flex;
  flex-direction: row;
  transform: rotate(-90deg);
  gap: 24px;
}

.counter {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: auto;
  height: 28px;
}

.active-slide-num {
  color: #10375c;
  font-family: Lato;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px;
}

.slash {
  width: 8px;
  text-align: center;
  color: #10375c;
  font-family: Roboto;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.total-slides {
  color: var(--Color-Gray-1, #9a9a9a);
  font-family: Lato;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 26px;
}

.indicators {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.indicator {
  height: 4px;
  width: 32px;
  background: var(--Color-Gray-3, #dfdfdf);
}

.active {
  background: #dc913f;
}

.facilities-list {
  display: flex;
  flex-direction: column;
  width: 293px;
  margin-left: -80px;
}

.facility {
  display: flex;
  height: 24px;
  padding: 24px;
  align-items: center;
  color: var(--Color-Black, #222831);
  font-family: Lato;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  cursor: pointer;
}

.non-active:hover {
  background: #f5f5f5;
}

.facility.active {
  filter: drop-shadow(0px 4px 4px rgba(180, 177, 0, 0.25));
  color: #ffffff;
}

.facilities-display {
  width: 729px;
  height: 453px;
  box-shadow: 0px 4px 16px 0px #10375c1a;
}

.facility-image {
  width: 729px;
  height: 453px;
  object-fit: cover;
}

@media screen and (max-width: 768px) {
  .facilities {
    width: 100vw;
    height: 100%;
    margin: 0;
    padding: 0 32px;
    padding-top: calc(500px - 100vw);
  }

  .facilities-title {
    width: 100%;
    margin: 0;
  }

  .facilities-title-line {
    width: 100%;
    margin: 0 auto;
  }

  .facilities-title-text {
    font-size: 16px;
    line-height: 24px;
  }

  .facilities-title-stroke {
    width: 50px;
  }

  .title-desc {
    width: 100%;
    font-size: 30px;
  }

  .facilities-container {
    flex-direction: column-reverse;
    gap: 0;
    margin-top: 32px;
  }

  .facilities-selector {
    width: 100%;
    margin: 0;
    justify-content: space-evenly;
    transform: scale(0.8);
  }

  .indicator-line {
    width: 100%;
    margin-left: -90px;
    margin-top: 135px;
    margin-right: -40px;
    justify-content: center;
  }

  .facilities-list {
    width: 100%;
    margin: 0;
  }

  .facility {
    padding: 16px;
    font-size: 16px;
    line-height: 24px;
  }

  .facilities-display {
    width: 100vw;
    height: 35vh;
  }

  .facility-image {
    width: 100%;
    height: 100%;
  }
}
