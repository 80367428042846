.workspace1 {
  position: relative;
  width: 1110px;
  height: 420px;
  margin: 0 auto;
}

.no-svg {
  border-radius: 5.77px;
  background: #10375c;
  cursor: pointer;
}

.ws-item {
  fill: #10375c;
  position: absolute;
}

.ws-item svg path {
  cursor: pointer;
}

.ws-name {
  position: absolute;
  width: fit-content;
  color: #fff;
  text-align: center;
  font-family: Lato;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 16px; /* 100% */
  z-index: 2;
  cursor: pointer;
}

.ws-name-subtext {
  position: absolute;
  width: fit-content;
  color: #fff;
  text-align: center;
  font-family: Lato;
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  line-height: 16px; /* 114.286% */
  z-index: 2;
  cursor: pointer;
}

.floor-number {
  position: absolute;
  left: 471px;
  top: 154px;
  color: #dc913f;
  font-family: Lato;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 28px; /* 116.667% */
}

.the-office {
  width: 383px;
  height: 342px;
}

.the-office .ws-name {
  inset: 45% 25%;
}

.the-office .ws-name-subtext {
  inset: 52% 32%;
}

.washroom {
  width: 149px;
  height: 180px;
  left: 360px;
}

.washroom .ws-name {
  inset: 17% 19%;
}

.printer {
  width: 77px;
  height: 88px;
  left: 388px;
  top: 254px;
}

.printer .ws-name {
  inset: 40% 9%;
}

.reception {
  width: 148px;
  height: 88px;
  left: 470px;
  top: 254px;
}

.reception .ws-name {
  inset: 40% 20%;
}

.calling-booths {
  width: 251px;
  height: 44px;
  left: 624px;
}

.calling-booths .ws-name {
  inset: 30% 22%;
}

.pantry {
  width: 123px;
  height: 58px;
  left: 624px;
  top: 50px;
}

.pantry .ws-name {
  inset: 35% 28%;
}

.lockers {
  width: 123px;
  height: 103px;
  left: 624px;
  top: 114px;
}

.lockers .ws-name {
  inset: 40% 22%;
}

.community-tables {
  width: 122px;
  height: 167px;
  left: 753px;
  top: 50px;
}

.community-tables .ws-name {
  inset: 37% 8%;
}

.phone-booth {
  width: 122px;
  height: 44px;
  left: 881px;
}

.phone-booth .ws-name {
  inset: 30% 5%;
}

.semi-private-booths {
  width: 122px;
  height: 122px;
  top: 50px;
  left: 881px;
}

.semi-private-booths .ws-name {
  inset: 27% 10%;
}

.pool-table {
  width: 205px;
  height: 88px;
  left: 753px;
  top: 254px;
}

.pool-table .ws-name {
  inset: 37% 27%;
}

.gather {
  width: 147px;
  height: 231px;
  left: 963px;
  top: 182px;
}

.gather .ws-name {
  inset: 42% 29%;
}

.gather .ws-name-subtext {
  inset: 52% 23%;
}

.workstations {
  width: 205px;
  height: 48px;
  left: 753px;
  top: 365px;
}

.workstations .ws-name {
  inset: 30% 20%;
}

.visiting-lounge {
  width: 123px;
  height: 48px;
  left: 624px;
  top: 365px;
}

.visiting-lounge .ws-name {
  inset: 11%;
}

.navigation {
  position: absolute;
  left: 239px;
  top: 365px;
  display: flex;
  flex-direction: row;
  gap: 23px;
  align-items: center;
}

.nav-item {
  width: fit-content;
  color: var(--Color-Black, #222831);
  font-family: Lato;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 28px; /* 175% */
}

.ws-arrows {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.conference-room {
  width: 190.286px;
  height: 243.623px;
}

.conference-room .ws-name {
  inset: 45% 24%;
}

.meeting-rooms {
  width: 144.156px;
  height: 92.26px;
  left: 197.49px;
}

.meeting-rooms .ws-name {
  inset: 30% 23%;
}

.workstations1 {
  width: 144.156px;
  height: 92.26px;
  left: 197.49px;
  top: 97.03px;
}

.workstations1 .ws-name {
  inset: 37% 8%;
}

.lavatory {
  width: 165.779px;
  height: 188.844px;
  left: 347.42px;
}

.lavatory .ws-name {
  inset: 18% 30%;
}

.community-table {
  width: 190.286px;
  height: 87.935px;
  top: 252.71px;
}

.community-table .ws-name {
  inset: 25% 20%;
}

.art-cafe {
  width: 400px;
  height: 88px;
  left: 197px;
  top: 253px;
}

.art-cafe .ws-name {
  inset: 37% 40%;
}

.cafe {
  width: 155.688px;
  height: 193.169px;
  left: 604px;
}

.cafe .ws-name {
  inset: 45% 37%;
}

.business-lounge {
  width: 257px;
  height: 194px;
  left: 765px;
}

.business-lounge .ws-name {
  inset: 40% 67% 45% 23%;
}

.library {
  width: 352px;
  height: 88px;
  left: 604px;
  top: 253px;
}

.library .ws-name {
  inset: 37% 42%;
}

.workstations2 {
  width: 352px;
  height: 64px;
  left: 604px;
  top: 349px;
}

.workstations2 .ws-name {
  inset: 34%;
}

.recording-room {
  width: 148.481px;
  height: 111px;
  left: 961.52px;
  top: 203.7px;
}

.recording-room .ws-name {
  inset: 32% 20%;
}

.sleeping-pods {
  width: 148.481px;
  height: 90.818px;
  left: 961.52px;
  top: 321.91px;
}

.sleeping-pods .ws-name {
  inset: 28% 20%;
}

.faded-item {
  opacity: 0.4;
}

.ws-yellow-arrow {
  cursor: pointer;
}

.ws-items-list {
  width: 1110px;
  margin: 100px auto;
  display: flex;
  flex-direction: column;
  gap: 116px;
}

@media screen and (max-width: 1000px) {
  .workspace1 {
    width: 100vw;
    height: 42vh;
    padding: 0;
    margin: 0;
    transform: scale(0.7);
    transform-origin: left;
    margin-bottom: 100px;
    margin-left: calc((100vw - 786px) / 2);
  }

  .ws-name {
    font-size: 20px;
    line-height: 20px;
  }

  .ws-name-subtext {
    font-size: 14px;
  }

  .nav-item {
    font-size: 20px;
  }

  .floor-number {
    font-size: 32px;
  }

  .ws-arrows {
    gap: 10px;
  }

  .ws-items-list {
    margin: 0;
    gap: 50px;
  }
}

@media screen and (max-width: 600px) {
  .workspaces {
    width: 100vw;
    display: flex;
    flex-direction: column;
    justify-content: start;
  }

  .workspace1 {
    transform: scale(0.41);
    transform-origin: left;
    width: 120vw;
    height: 30vh;
    padding: 0;
    margin: 0;
    margin-left: calc((100vw - 390px) / 2);
    margin-bottom: calc(430px - 100vw);
  }

  .ws-name {
    font-size: 18px;
  }

  .ws-name-subtext {
    font-size: 13px;
  }

  .floor-number {
    font-size: 28px;
    width: 100px;
  }

  .ws-arrows {
    gap: 10px;
  }

  .nav-item {
    font-size: 24px;
    width: 80px;
  }

  .ws-items-list {
    width: 100vw;
    margin: 50px 0;
    gap: 80px;
  }

  .navigation {
    transform: translate(-10%, 15%);
  }
}
