.mission {
  width: 1110px;
  display: flex;
  align-items: center;
  gap: 54px;
  margin: 64px auto;
}

.mission-text {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 11px;
  margin: auto 0;
}

.mission-title {
  color: #000;
  font-family: "Source Serif Pro";
  font-size: 36px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.mission-stroke {
  width: 309px;
  height: 3px;
  background: #dc913f;
}

.mission-desc {
  width: 528px;
  color: var(--Gray-1, #333);
  font-family: Lato;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-align: right;
}

.mission-img {
  width: 528px;
  height: 320px;
}

.mission-img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

@media screen and (max-width: 768px) {
  .mission {
    width: 100vw;
    flex-direction: column-reverse;
    margin-left: 32px;
  }

  .mission-img {
    width: 100%;
    height: auto;
  }

  .mission-title {
    text-align: left;
  }

  .mission-stroke{
    width: 55%;
  }


  .mission-text {
    width: 100%;
    gap: 20px;
  }

  .mission-desc {
    width: 100%;
    text-align: left;
  }
}
