.scroll-to-top {
  position: fixed;
  bottom: 40px;
  right: 40px;
  background-color: #dc913f;
  color: white;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  z-index: 100;
  cursor: pointer;
}

.scroll-to-top svg {
  width: 30px;
  height: 30px;
  margin: 10px;
  transform: rotate(180deg);
}

@media screen and (max-width: 768px) {
  .scroll-to-top {
    bottom: 30px;
    right: 30px;
    width: 10vw;
    height: 10vw;
  }

  .scroll-to-top svg {
    width: 6vw;
    height: 6vw;
    margin: 2vw;
  }
}
