.faq-item {
  width: 1110px;
  height: auto;
  display: none;
  flex-direction: column;
  align-items: center;
  border-radius: 18px;
  background: var(--Neutral-100, #fff);
  box-shadow: 0px 5px 16px 0px rgba(8, 15, 52, 0.06);
}

.faq-content {
  display: flex;
  width: 1017.022px;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  margin: 25px 48px;
}

.faq-item-topic {
  width: 1016.737px;
  height: 50.662px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.faq-question {
  color: #10375c;
  font-feature-settings: "clig" off, "liga" off;
  font-family: "DM Sans";
  font-size: 22px;
  font-style: normal;
  font-weight: 500;
  line-height: normal; /* 127.273% */
  text-align: left;
}

.faq-icon {
  position: relative;
  width: 63.369px;
  height: 50.662px;
  margin-top: 40px;
  margin-right: 48px;
  margin-bottom: 40px;
  z-index: 10;
}

.chevron-button-right {
  position: absolute;
  bottom: -20px;
  right: -45px;
  cursor: pointer;
  z-index: 10;
}

.chevron-button-down {
  position: absolute;
  right: -40px;
  top: -2px;
  cursor: pointer;
  z-index: 10;
}

.faq-answer {
  width: 954px;
  margin-bottom: -6px;
  color: #9a9a9a;
  font-feature-settings: "clig" off, "liga" off;
  font-family: "DM Sans";
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal; /* 166.667% */
}

.faq-answer p {
  margin: 6px 0;
}

@media screen and (max-width: 768px) {
  .faq-item {
    width: 100vw;
    height: auto;
  }

  .faq-content {
    width: 100%;
    padding: 0;
  }

  .faq-item-topic {
    width: 90%;
    padding: 0;
  }

  .faq-question {
    font-size: 16px;
  }

  .faq-icon {
    right: 1%;
    top: -4px;
    transform: scale(0.7);
  }

  .faq-answer {
    width: 80%;
    padding: 0;
    font-size: 16px;
  }
}
