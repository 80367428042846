.hero {
  width: 1110px;
  margin: auto;
  margin-top: 50px;
  display: flex;
  flex-direction: column;
}

.intro {
  display: flex;
  flex-direction: row;
  gap: 15px;
}

.introducing {
  color: #222831;
  font-family: Lato;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 30px; /* 150% */
}

.stroke {
  width: 73px;
  height: 2px;
  flex-shrink: 0;
  background: var(--Color-Black, #222831);
  margin-top: 17px;
  margin-bottom: 11px;
}

.content {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.slogan {
  margin-top: 10px;
  width: 667px;
}

.slogan1 {
  display: inline;
  color: #dc913f;
  font-family: Lora;
  font-size: 52px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.slogan2 {
  display: inline;
  color: #10375c;
  font-family: Lora;
  font-size: 52px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.about {
  margin-top: 20px;
  color: #6a6a6a;
  width: 382px;
  font-family: Lato;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 30px;
}

.img-slider {
  position: absolute;
  right: 0;
  left: 200px;
  top: 400px;
}

.search-form {
  position: relative;
  z-index: 10;
}

@media screen and (max-width: 768px) {
  .hero {
    width: 110vw;
    margin: 0 0 0 32px;
    margin-top: 0;
  }

  .introducing {
    font-size: 16px;
    line-height: 22px;
  }

  .about {
    font-size: 18px;
    margin-top: 5px;
  }

  .stroke {
    width: 50px;
    margin-top: 12px;
    margin-bottom: 8px;
  }

  .intro {
    width: 100%;
    margin: 0 auto;
    margin-bottom: 10px;
    gap: 7px;
  }

  .content {
    flex-direction: column;
    gap: 10px;
    width: 110vw;
    margin: 0 auto;
  }

  .slogan {
    width: 80%;
    margin: 0;
  }

  .slogan1 {
    font-size: 30px;
  }

  .slogan2 {
    font-size: 30px;
  }

  .img-slider {
    width: 115vw;
    height: 350px;
    margin: 0;
    margin-top: calc(520px - 100vw);
    left: 10px;
    top: 300px;
  }

  .search-form {
    transform: scale(0.65);
    transform-origin: bottom left;
    top: -10vw;
  }
}
