.ws-item-container {
  display: flex;
  flex-direction: column;
  gap: 56px;
}

.ws-item-title {
  color: #10375c;
  font-family: Lora;
  font-size: 40px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.ws-item-details {
  display: flex;
  align-items: flex-start;
  gap: 44px;
}

.ws-item-img {
  width: 536px;
  height: 333px;
  flex-shrink: 0;
  position: relative;
  box-shadow: 0px 4px 20.9px 0px rgba(5, 48, 68, 0.1);
}

.ws-item-overlay {
  width: 255px;
  height: 72px;
  background: #dc913f;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: 0;
  bottom: 0;
}

.availability-text {
  color: var(--Color-White, #fff);
  text-align: center;
  font-family: Lato;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin: 24px 0 26px 44px;
}

.availability-right-arrow {
  margin: 20px 20px 20px 16px;
}

.ws-item-img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.ws-item-desc {
  display: flex;
  width: 530px;
  height: 333px;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
}

.ws-desc-text {
  color: #6a6a6a;
  font-family: Lato;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 30px; /* 150% */
  margin: 0;
}

.ws-features {
  display: flex;
  flex-direction: column;
  gap: 32px;
}

.desc-features {
  display: flex;
  align-items: flex-start;
  gap: 24px;
  align-self: stretch;
}

@media screen and (max-width: 768px) {
  .ws-item-container {
    width: 100vw;
    gap: 32px;
    margin: 0 40px;
  }

  .ws-item-title {
    font-size: 30px;
  }

  .ws-item-details {
    flex-direction: column;
    gap: 32px;
  }

  .ws-item-img {
    width: 100vw;
    height: 250px;
    margin: 0;
  }

  .ws-item-overlay {
    width: 60vw;
    height: 50px;
    margin: 0;
  }

  .availability-text {
    font-size: 16px;
    margin-left: 7vw;
  }

  .ws-item-desc {
    width: 100vw;
    height: auto;
    gap: 24px;
    margin: 0;
  }

  .desc-features {
    width: 100%;
    gap: 16px;
    align-items: center;
    justify-content: space-between;
  }
}
