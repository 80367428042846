.space-image-card {
  width: 350px;
  height: 468px;
  position: relative;
}

.space-image {
  width: 350px;
  height: 419px;
  box-shadow: 0px 4px 7px 0px rgba(97, 70, 0, 0.15);
}

.space-button-container {
  width: 72px;
  height: 72px;
  background: #dc913f;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  left: 0;
  bottom: 49px;
}

.space-image-text {
  color: var(--Color-Black, #222831);
  text-align: center;
  font-family: Lato;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-top: 25px;
}

@media screen and (max-width: 768px) {
  .space-image-card {
    width: 100vw;
    height: 30vh;
    margin: 0 auto;
  }

  .space-image {
    width: 100%;
    height: 100%;
  }

  .space-button-container {
    width: 100px;
    height: 60px;
    bottom: 0;
  }

  .space-button-container svg{
    width: 50px;
    height: 50px;
  }

  .space-image-text {
    margin: 18px;
    font-size: 20px;;
  }
}
