.faq-section {
  width: 1110px;
  margin: 90px auto 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-items: center;
}

.faq-title {
  width: 578px;
  color: #10375c;
  text-align: center;
  font-family: Lora;
  font-size: 40px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin: 0 auto;
  margin-bottom: 45px;
}

.faq-cards-wrapper {
  margin: auto;
  width: auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 30px;
}

.faq-more-questions-btn {
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
  cursor: pointer;
  margin: 45px 0 30px 0;
}

.more-questions-text {
  color: #dc913f;
  font-family: Lato;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 26px; /* 162.5% */
  margin-bottom: 4px;
}

.faq-items-wrapper {
  display: flex;
  flex-direction: column;
  gap: 29px;
}

@media screen and (max-width: 768px) {
  .faq-section {
    width: 100vw;
    padding: 0 32px;
    margin-top: 50px;
  }

  .faq-title {
    width: 100%;
    font-size: 30px;
    margin-bottom: 60px;
  }

  .faq-cards-wrapper {
    flex-direction: column;
    gap: 35px;
  }

  .faq-more-questions-btn {
    gap: 8px;
    margin: 40px 0 30px 0;
  }

  .faq-items-wrapper {
    gap: 20px;
  }
}
