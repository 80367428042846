.member {
  display: flex;
  flex-direction: row;
  height: 500px;
  width: 100%;
  flex-shrink: 0;
  background: #10375c;
  justify-content: space-evenly;
  align-items: center;
  margin-top: 60px;
}

.member-signup {
  display: flex;
  flex-direction: column;
  width: 445px;
  height: 302px;
  flex-shrink: 0;
}

.member-become {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 15px;
  margin-bottom: 8px;
}

.become-text {
  color: var(--Color-Gray-4, #f8f8f8);
  font-family: Lato;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 30px; /* 150% */
}

.become-stroke {
  width: 73px;
  height: 2px;
  background: var(--Color-Gray-4, #f8f8f8);
}

.member-experience-title {
  margin-bottom: 16px;
  color: var(--Color-White, #fff);
  font-family: Lora;
  font-size: 40px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.member-experience-text {
  margin-bottom: 32px;
  color: var(--Color-Gray-3, #dfdfdf);
  font-family: Lato;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 30px; /* 150% */
}

.member-signup-btn {
  display: inline-flex;
  height: 53px;
  align-items: center;
  flex-shrink: 0;
  background: #dc913f;
}

.mbr-signup-btn {
  padding: 43px 162px;
  color: var(--Color-White, #fff);
  font-family: Lato;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 28px; /* 155.556% */
}

.member-pic {
  width: 450px;
  height: 400px;
  flex-shrink: 0;
  border-radius: 5px;
  background: url("../../public/images/Pattern1.png");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-blend-mode: darken;
}

.member-pic img {
  margin-top: 35px;
  width: 450px;
  height: 374px;
  flex-shrink: 0;
  border-radius: 5px;
}

@media screen and (max-width: 768px) {
  .member {
    width: 120vw;
    height: 450px;
    margin: 0;
    margin-top: 10%;
  }

  .member-signup {
    width: 80%;
    padding: 0 12vw;
  }

  .member-become {
    gap: 7px;
  }

  .become-text {
    font-size: 16px;
  }

  .become-stroke {
    width: 50px;
  }

  .member-experience-text {
    width: 100%;
    font-size: 18px;
  }

  .member-signup-btn {
    width: 100%;
  }

  .mbr-signup-btn {
    padding: 0 30vw;
  }

  .member-pic {
    display: none;
  }

  .member-experience-title {
    font-size: 30px;
    width: 100%;
  }
}
